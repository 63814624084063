import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../../Store/hooks";
import { STRAPI_DOMAIN } from "../../../../../URLCollection/Domain";

function MMDCEO() {
  const { md_ceo } = useAppSelector((state) => state.CMS);

  return (
    <Box>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <Box className="personnelbox2">
            <Box textAlign={"center"} marginBottom={"24px"}>
              <img
                alt=""
                src={
                  STRAPI_DOMAIN +
                  md_ceo.data.attributes.directors_image.data.attributes.url
                }
              />
            </Box>
            <h4>{md_ceo.data.attributes.directors_name}</h4>
            <h5>{md_ceo.data.attributes.directors_desg}</h5>
            <h5>Insurance Industry Veteran with 35 Years of Experience</h5>
            <p>{md_ceo.data.attributes.directors_desc}</p>
            <p className="mt-2">
              {md_ceo.data.attributes.awards.few_words_sub_desc_2}
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <img
            className="awardsRec-icon"
            alt=""
            src={
              STRAPI_DOMAIN +
              md_ceo.data.attributes.awards.image.data.attributes.url
            }
          />
        </Grid>
        <Grid xs={12}>
          <h4>{md_ceo.data.attributes.awards.title}</h4>
          <p style={{ fontSize: "14px", fontWeight: 500, marginTop: "8px" }}>
            Dr. Reddy's contributions have been recognized through numerous
            accolades, including:
          </p>
        </Grid>
        <Grid xs={12}>
          <ul className="ms_points_list">
            {md_ceo.data.attributes.awards.data.map((data, index) => (
              <li>
                <img alt="" src="./images/list-tick-icon.svg" />
                <p>{data.desc}</p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <h4 style={{ marginBottom: "10px", textAlign: "center" }}>
            {md_ceo.data.attributes.awards.few_words_heading}
          </h4>
          {/* <p
            className="desc_p"
            style={{ marginBottom: "10px", fontWeight: "600" }}
          >
            {md_ceo.data.attributes.awards.few_words_desc}
          </p> */}
          <p className="desc_p" style={{ marginBottom: "10px" }}>
            {md_ceo.data.attributes.awards.few_words_sub_desc}
          </p>
          {/* <p className="desc_p">
            {md_ceo.data.attributes.awards.few_words_sub_desc_2}
          </p> */}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12} textAlign={"center"}>
          <h2 style={{ marginBottom: "10px" }}>
            {md_ceo.data.attributes.Experience.title}
          </h2>
          <h5>{md_ceo.data.attributes.Experience.desc}</h5>
        </Grid>
        <Grid xs={12} textAlign={"center"}>
          <ul className="es_list">
            {md_ceo.data.attributes.Experience.data.map((data) => (
              <li>
                <img
                  src={STRAPI_DOMAIN + data.image.data.attributes.url}
                  alt=""
                />
                <p>{data.desc}</p>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Grid container spacing={3} rowSpacing={0} marginBottom={"40px"}>
        <Grid xs={12} className="cs_offerd_sec">
          <h2 style={{ marginBottom: "10px" }}>
            {md_ceo.data.attributes.Solutions.title}
          </h2>
          <h5>{md_ceo.data.attributes.Solutions.desc}</h5>
          <ul className="csOffered_listpoints">
            {md_ceo.data.attributes.Solutions.data.map((data, index) => (
              <li className={index % 2 === 0 ? "" : "evenRow"}>
                <img
                  src={STRAPI_DOMAIN + data.image.data.attributes.url}
                  alt=""
                />
                <Box>
                  <h5>{data.title}</h5>
                  <p className="desc_p">{data.desc}</p>
                </Box>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      {md_ceo.data.attributes.Achievements.map((data) => (
        <Grid
          container
          spacing={3}
          rowSpacing={0}
          rowGap={"20px"}
          marginBottom={"40px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "10px" }}>{data.title}</h2>
            <p className="desc_p">{data.desc}</p>
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <ul className="tenure_achievements">
              {data.data.map((sub_data) => (
                <>
                  <li>
                    <img alt="" src="./images/green-tick.svg" />
                    <p className="desc_p">{data.desc}</p>
                  </li>
                  {/* <ul className="ms_points_list">
                    {data.achievementSubpoints?.map((subData) => (
                      <li>
                        <img alt="" src="./images/list-tick-icon.svg" />
                        <p>{subData.subpoint}</p>
                      </li>
                    ))}
                  </ul> */}
                </>
              ))}
            </ul>
          </Grid>
        </Grid>
      ))}

      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <h2 style={{ marginBottom: "10px", textAlign: "center" }}>
            Legacy of Excellence
          </h2>
          <p className="desc_p" style={{ marginBottom: "10px" }}>
            Throughout his career, Dr. Reddy has pioneered unique insurance
            schemes, facilitated corporate partnerships, and provided invaluable
            risk management solutions. His dedication to client satisfaction and
            industry innovation continues to define his legacy in the insurance
            landscape.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        rowSpacing={0}
        rowGap={"20px"}
        marginBottom={"40px"}
      >
        <Grid xs={12}>
          <h2 style={{ marginBottom: "10px", textAlign: "center" }}>
            The Journey Continues
          </h2>
          <p className="desc_p" style={{ marginBottom: "10px" }}>
            As Managing Director & Principal Officer of Amaze Insurance Brokers
            Pvt Ltd since January 2016, Dr. Reddy remains steadfast in his
            mission to deliver world-class insurance solutions, driving positive
            change and elevating the reputation of Indian Insurance Brokers on
            the global stage.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MMDCEO;
